import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer/footer';
import HeaderWithList from '../components/headerWithList/headerWithList';
import BackButton from '../components/helpBackButton/helpBackButton';
import BootstrapNavbar from '../components/navgationBar/navigationBar';
import { MainContainer } from '../styles';
import GlobalStyles from '../styles/globalStyles';
import { PropaneSafetyGuidelines } from '../utils/constant';

export default function SafetyGuidelines() {
  return (
    <>
      <Helmet>
        <title>Propane Safety Guidelines | Pro2Go</title>
        <meta
          name="description"
          content="Tips for handling propane cylinders in a safe matter"
        ></meta>
        <meta
          name="keywords"
          content="bbq cylinders,propane cylinders,cylinder exchange service,london ontario,canada"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
      </Helmet>
      <GlobalStyles />
      <BootstrapNavbar />
      <MainContainer>
        <HeaderWithList sectionData={PropaneSafetyGuidelines[0]} />
        <HeaderWithList sectionData={PropaneSafetyGuidelines[1]} />
        <HeaderWithList sectionData={PropaneSafetyGuidelines[2]} />
        <HeaderWithList sectionData={PropaneSafetyGuidelines[3]} />
        <HeaderWithList sectionData={PropaneSafetyGuidelines[4]} />
        <HeaderWithList sectionData={PropaneSafetyGuidelines[5]} />
        <BackButton />
      </MainContainer>
      <Footer />
    </>
  );
}
