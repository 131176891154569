import React from 'react';
import * as Styled from './headerWithListStyle';

const HeaderWithList = ({ sectionData }) => {
  return (
    <React.Fragment>
      {sectionData?.header1 && (
        <Styled.HeaderH1>{sectionData?.header1}</Styled.HeaderH1>
      )}
      {sectionData?.header5 && (
        <Styled.HeaderH5>{sectionData?.header5}</Styled.HeaderH5>
      )}
      {sectionData?.list && (
        <Styled.UnOrderedList>
          {sectionData?.list?.map((item, index) => {
            return <Styled.ListItem key={index}>{item}</Styled.ListItem>;
          })}
        </Styled.UnOrderedList>
      )}
    </React.Fragment>
  );
};

export default HeaderWithList;
