import styled from 'styled-components';

export const HeaderH1 = styled.h1`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #4e4e4e;
  font-size: 1.75em;
  line-height: 1em;
  text-transform: uppercase;
  text-align: left;
  margin: 20px 0;
  @media (max-width: 767px) {
    text-align: left;
  }
`;

export const UnOrderedList = styled.ul`
  margin-left: 17px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.1em;
  line-height: 1.3em;
  font-style: normal;
  padding: 0 !important;
`;

export const HeaderH5 = styled.h5`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  color: #263b91;
  font-size: 1.25em;
  line-height: 1em;
  font-style: normal;
  margin-bottom: 14px;
  text-rendering: optimizeLegibility;
  direction: ltr;
`;

export const ListItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000000;
  line-height: 1.3em;
  font-style: normal;
  margin: 0;
  padding: 0;
  font-size: 14px;
  direction: ltr;
`;
